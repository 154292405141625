/* You can add global styles to this file, and also import other style files */
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css";
@import "https://use.fontawesome.com/releases/v5.8.1/css/all.css";
@import "node_modules/angular-notifier/styles";
@import "node_modules/primeicons/primeicons.css";
@import "node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "node_modules/primeng/resources/primeng.min.css";
@import "node_modules/angular-notifier/styles";
/* this css for global sidebar */
.sidebar {
  z-index: 999 !important;
}

td {
  cursor: pointer;
}
.move-right {
  position: absolute;
  right: 0;
}

.move-left {
  position: absolute;
  left: 0;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.sidebar.active ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
}

.home-section {
  position: relative;
  background: #f5f5f5;
  min-height: 100vh;
  width: calc(100% - 240px);
  left: 240px;
  transition: all 0.5s ease;
}

.home-section nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.sidebar.active ~ .home-section nav {
  left: 60px;
  width: calc(50% - 60px);
}

.home-section nav .sidebar-button {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

nav .sidebar-button i {
  font-size: 35px;
  margin-right: 10px;
}

.home-section nav .search-box {
  position: relative;
  height: 50px;
  max-width: 550px;
  width: 100%;
  margin: 0 20px;
}

nav .search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 15px;
}

nav .search-box .bx-search {
  position: absolute;
  height: 40px;
  width: 40px;
  background: #2697ff;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  transition: all 0.4 ease;
}

.home-section nav .profile-details {
  display: flex;
  align-items: center;
  background: #f5f6fa;
  border: 2px solid #efeef1;
  border-radius: 6px;
  height: 50px;
  min-width: 190px;
  padding: 0 15px 0 2px;
}

nav .profile-details img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  object-fit: cover;
}

nav .profile-details .admin_name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin: 0 10px;
  white-space: nowrap;
}

nav .profile-details i {
  font-size: 25px;
  color: #333;
}

.home-section .home-content {
  position: relative;
  padding-top: 104px;
}

.home-content .overview-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
}

.overview-boxes .box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(80% / 4 - 5px);
  background: #fff;
  padding: 15px 14px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.overview-boxes .box-topic {
  font-size: 20px;
  font-weight: 500;
}

.home-content .box .number {
  display: inline-block;
  font-size: 5px;
  margin-top: -6px;
  font-weight: 50;
}

.home-content .box .indicator {
  display: flex;
  align-items: center;
}

.home-content .box .indicator i {
  height: 10px;
  width: 10px;
  background: #8fdacb;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
}

.box .indicator i.down {
  background: #e87d88;
}

.home-content .box .indicator .text {
  font-size: 12px;
}

.home-content .box .cart {
  display: inline-block;
  font-size: 32px;
  height: 50px;
  width: 50px;
  background: #cce5ff;
  line-height: 50px;
  text-align: center;
  color: #66b0ff;
  border-radius: 12px;
  margin: -15px 0 0 6px;
}

.home-content .box .cart.two {
  color: #2bd47d;
  background: #c0f2d8;
}

.home-content .box .cart.three {
  color: #ffc233;
  background: #ffe8b3;
}

.home-content .box .cart.four {
  color: #e05260;
  background: #f7d4d7;
}

.home-content .total-order {
  font-size: 20px;
  font-weight: 500;
}

.home-content .sales-boxes {
  padding: 0 20px;
}

/* left box */
.home-content .sales-boxes .recent-sales {
  width: 5%;
  background: #fff;
  padding: 10px 10px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.home-content .sales-boxes .sales-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales-boxes .box .title {
  font-size: 24px;
  font-weight: 500;
  /* margin-bottom: 10px; */
}

.sales-boxes .sales-details li.topic {
  font-size: 20px;
  font-weight: 500;
}

.sales-boxes .sales-details li {
  list-style: none;
  margin: 8px 0;
}

.sales-boxes .sales-details li a {
  font-size: 18px;
  color: #333;
  font-size: 400;
  text-decoration: none;
}

.sales-boxes .box .button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sales-boxes .box .button a {
  color: #fff;
  background: #0a2558;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sales-boxes .box .button a:hover {
  background: #0d3073;
}

/* Right box */
.home-content .sales-boxes .top-sales {
  width: 35%;
  background: #fff;
  padding: 20px 30px;
  margin: 0 20px 0 0;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.sales-boxes .top-sales li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.sales-boxes .top-sales li a img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 10px;
  background: #333;
}

.sales-boxes .top-sales li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sales-boxes .top-sales li .product,
.price {
  font-size: 17px;
  font-weight: 400;
  color: #333;
}

/* Responsive Media Query */
@media (max-width: 1240px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.active {
    width: 220px;
  }

  .home-section {
    width: calc(50% - 60px);
    left: 30px;
  }

  .sidebar.active ~ .home-section {
    /* width: calc(100% - 220px); */
    overflow: hidden;
    left: 220px;
  }

  .home-section nav {
    width: calc(100% - 60px);
    left: 60px;
  }

  .sidebar.active ~ .home-section nav {
    width: calc(100% - 220px);
    left: 220px;
  }
}

@media (max-width: 150px) {
  .home-content .sales-boxes {
    flex-direction: column;
  }

  .home-content .sales-boxes .box {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }

  .home-content .sales-boxes .top-sales {
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .overview-boxes .box {
    width: calc(100% / 2);
    margin-bottom: 15px;
  }
}

@media (max-width: 700px) {
  nav .sidebar-button .dashboard,
  nav .profile-details .admin_name,
  nav .profile-details i {
    display: none;
  }

  .home-section nav .profile-details {
    height: 50px;
    min-width: 40px;
  }

  .home-content .sales-boxes .sales-details {
    width: 560px;
  }
}

@media (max-width: 550px) {
  .overview-boxes .box {
    width: 100%;
    margin-bottom: 15px;
  }

  .sidebar.active ~ .home-section nav .profile-details {
    display: none;
  }
}

@media (max-width: 400px) {
  .sidebar {
    width: 0;
  }

  .sidebar.active {
    width: 60px;
  }

  .home-section {
    width: 20%;
    left: 0;
  }

  .home-section nav {
    width: 50%;
    left: 0;
  }

  .sidebar.active ~ .home-section nav {
    left: 0px;
    width: calc(100% - 60px);
  }
}

.=_name {
  display: flex;
  align-items: center;
  height: 64px;
  cursor: pointer;

  img {
    max-height: 95px;
  }
}

flex-container {
  display: flex;
  background-color: #32a852;
}

.flex-container div {
  background-color: #c9d1cb;
  margin: 10px;
  padding: 10px;
}

.container-fluid,
.page-wrapper > .container-lg,
.page-wrapper > .container-md,
.page-wrapper > .container-sm,
.page-wrapper > .container-xl,
.page-wrapper > .container-xxl {
  padding: 20px;
  min-height: calc(100vh - 180px);
}

.row {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
}

.card-hover {
  transition: all 0.25s ease;
}

.card {
  margin-bottom: 20px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid transparent;
  border-radius: 0px;
}

.box {
  border-radius: 2px;
  padding: 10px;
}

.bg-cyan {
  background-color: #27a9e3 !important;
}

.text-white {
  color: #fff !important;
}
.card-hover:hover {
  webkit-transform: translateY(-4px) scale(1.01);
  transform: translateY(-4px) scale(1.01);
  box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
}

.card-hover {
  transition: all 0.25s ease;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
